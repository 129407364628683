import { FC } from 'react';
import { Node, mergeAttributes, ReactNodeViewRenderer, NodeViewProps } from '@tiptap/react';

export const createMathTypeExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;
  const node = Node.create({
    name: 'mathType',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    addAttributes() {
      return {
        html: {
          default: null,
        },
        latexParts: {
          default: [],
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'react-component[data-type="mathType"]',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes, { 'data-type': 'mathType' })];
    },
    addNodeView,
  });

  return node;
};
