import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment StandardItem on StandardModel {
    id
    externalId
    name
    title
    version
    language
    link
    isValid
    parent
    child
    lastChangedAt
    unableToSync
  }
`);

export const ALL_STANDARDS = gql(/* GraphQL */ `
  query GetAllStandards {
    standards {
      ...StandardItem
      guideRevisions
    }
  }
`);

export const GET_STANDARD = gql(/* GraphQL */ `
  query GetStandard($id: UUID!) {
    standard(id: $id) {
      ...StandardItem
      guides {
        id
        status
        hasChangesSinceLastPublish
        lastPublishedAt
        ongoingRevision {
          id
        }
        mainCategory {
          id
          title
        }
        docId
        docName
        docTitle
        owner {
          subjectId
          name
        }
      }
    }
  }
`);

export const ADD = gql(/* GraphQL */ `
  mutation StandardAdd($input: StandardAddInput!) {
    standardAdd(input: $input) {
      standardModel {
        ...StandardItem
      }
      errors {
        code: __typename
        ... on StandardAlreadyExistsError {
          message
        }
      }
    }
  }
`);

export const UPDATE_TITLE = gql(/* GraphQL */ `
  mutation StandardUpdateTitle($input: StandardUpdateTitleInput!) {
    standardUpdateTitle(input: $input) {
      standardModel {
        ...StandardItem
      }
    }
  }
`);

export const UPDATE_EXTERNAL_ID = gql(/* GraphQL */ `
  mutation StandardUpdateExternalId($input: StandardUpdateExternalIdInput!) {
    standardUpdateExternalId(input: $input) {
      standardModel {
        ...StandardItem
      }
      errors {
        code: __typename
        ... on StandardArgumentError {
          message
        }
        ... on StandardNotFoundError {
          message
        }
      }
    }
  }
`);

export const GUIDE = gql(/* GraphQL */ `
  query GetStandardsToGuide($id: UUID!) {
    guide(id: $id) {
      id
      docTitle
      standards {
        id
        name
        title
      }
    }
    standards {
      ...StandardItem
    }
  }
`);

export const GUIDE_ADD_STANDARD = gql(/* GraphQL */ `
  mutation GuideAddStandard($input: GuideAddStandardInput!) {
    guideAddStandard(input: $input) {
      guideModel {
        id
        standards {
          id
          name
          title
        }
      }
    }
  }
`);

export const GUIDE_REMOVE_STANDARD = gql(/* GraphQL */ `
  mutation GuideRemoveStandard($input: GuideRemoveStandardInput!) {
    guideRemoveStandard(input: $input) {
      guideModel {
        id
        standards {
          id
          name
          title
        }
      }
    }
  }
`);

export const FETCH_STANDARD = gql(/* GraphQL */ `
  query GetStandardFromAuthority($name: String!) {
    standardResponse: standardFromAuthority(name: $name) {
      isDuplicate
      standard {
        externalId
        name
        title
        version
        language
        link
        isValid
        parent
        child
      }
      error {
        code
        message
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation StandardDelete($input: StandardDeleteInput!) {
    standardDelete(input: $input) {
      standardModel {
        id
      }
    }
  }
`);
