import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GUIDE_CONTENT_VERSIONS } from '../content.graphql';
import Loading from '../../../LoadingSpinner';
import { formatDate } from '../../../Formatters';
import { useRestoreContentVersion } from './useRestoreContentVersion';
import { Restore } from '@mui/icons-material';
import { Modal } from '../../../dialogs/useModal';

export interface GuideChangeHistoryDialogResult {
  version: number;
  action: 'navigate' | 'restore';
}

export interface GuideChangeHistoryDialogProps {
  guideId: string;
}

export const GuideChangeHistoryDialog: FC<Modal<GuideChangeHistoryDialogProps, GuideChangeHistoryDialogResult>> = (modal) => {
  const { close } = modal;
  const guideId = modal.data.guideId;
  const { restoreVersion, isReady: isReadyToRestore, isRestoring } = useRestoreContentVersion(guideId);

  const { data, loading: isLoadingContentVersions } = useQuery(GUIDE_CONTENT_VERSIONS, {
    variables: { id: guideId },
    fetchPolicy: 'cache-and-network',
  });

  const onRestoreVersionClick = async (versionToRestore: number) => {
    try {
      const version = await restoreVersion(versionToRestore);
      if (version) {
        const dialogResult: GuideChangeHistoryDialogResult = {
          version,
          action: 'restore',
        };
        close(dialogResult);
      } else {
        close();
      }
    } catch (e) {
      console.error(`Failed to restore content to version ${restoreVersion}`, e);
    }
  };

  const onNavigateToVersion = (version: number) => {
    const dialogResult: GuideChangeHistoryDialogResult = {
      version,
      action: 'navigate',
    };
    close(dialogResult);
  };

  const handleOnClose = () => {
    close();
  };

  const isLoading = !isReadyToRestore || isRestoring || isLoadingContentVersions;
  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Tidligere versjoner av innholdet</DialogTitle>
      <DialogContent>
        {isLoading && <Loading />}
        {!isLoading && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Versjon</TableCell>
                <TableCell>Kommentar</TableCell>
                <TableCell>Sist oppdatert</TableCell>
                <TableCell>Sist oppdatert av</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.guide?.contents?.map((content) => {
                const isCurrentVersion = data.guide?.content?.id === content?.id;
                return (
                  <TableRow key={content?.id}>
                    <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: isCurrentVersion ? 'bolder' : undefined }}>
                      {isCurrentVersion && <>Gjeldende (v. {content?.version})</>}
                      {!isCurrentVersion && (
                        <Link component="button" onClick={() => onNavigateToVersion(content?.version!)}>
                          v. {content?.version}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: '100%' }}>{content?.comment}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: isCurrentVersion ? 'bolder' : undefined }}>
                      {formatDate(content?.lastChangedAt)}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: isCurrentVersion ? 'bolder' : undefined }}>
                      {content?.lastChangedBy?.name}
                    </TableCell>
                    <TableCell sx={{ width: 'auto' }}>
                      {' '}
                      {!isCurrentVersion && content?.version && (
                        <Button
                          disabled={!isReadyToRestore || isRestoring}
                          variant="outlined"
                          onClick={() => onRestoreVersionClick(content?.version)}
                          startIcon={<Restore />}>
                          Gjenopprett
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOnClose}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );
};
