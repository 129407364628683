import React, { useState } from 'react';
import { FormControlLabel, Button, Typography, Checkbox } from '@mui/material';
import { BackButton } from '../Components';
import { EditGuideStandardTable } from './GuideStandardTable';
import { useQuery } from '@apollo/client';
import { GUIDE } from './standards.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { SearchField } from '../components/SearchField';
import { useParams } from 'react-router-dom';
import { GetStandardsToGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { AddStandardDialog, StandardAdded } from './AddStandardDialog';
import { useModal } from '../dialogs/useModal';
import Fuse from 'fuse.js';

type Standard = Unpacked<GetStandardsToGuideQuery['standards']>;
type GuideModel = Unpacked<GetStandardsToGuideQuery['guide']>;

export function EditGuideStandards() {
  const { id } = useParams();
  const { data, loading } = useQuery(GUIDE, { variables: { id } });
  const [onlySelected, setOnlySelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const addModal = useModal<undefined, StandardAdded>({ data: undefined });

  if (!data || loading) return <LoadingSpinner />;

  const addStandard = async (): Promise<any> => {
    return addModal.open();
  };

  const guide = data.guide;
  const standards = filterStandards(onlySelected ? filterStandardsOnGuide(data.standards, guide) : data.standards, searchTerm);

  return (
    <div>
      <div>
        <Typography variant="h5">
          <BackButton />
          Anvisning: {guide?.docTitle}
        </Typography>
      </div>
      <SearchField filter={setSearchTerm} />
      <CreateText standards={standards} />
      {!standards ||
        (standards.length === 0 && (
          <Typography variant="caption">
            Finner du ikke standarden i listen? Da er den mest sannsynlig ikke i bruk (ingen anvisninger knyttet til den). Legg til ny under gjeldende
            anvisning.
          </Typography>
        ))}
      <FormControlLabel
        control={<Checkbox color="primary" onChange={() => setOnlySelected(!onlySelected)} checked={onlySelected} />}
        label="Vis bare valgte standarder"
      />
      <Button onClick={addStandard} variant="outlined">
        Legg til ny standard
      </Button>
      <EditGuideStandardTable standards={standards} guide={guide} />
      {addModal.isOpen && <AddStandardDialog modal={addModal} />}
    </div>
  );
}

function filterStandardsOnGuide(standards: Standard[] | null | undefined, guide: GuideModel): Standard[] | null | undefined {
  const guideStandards = guide?.standards?.map((x) => x?.id);
  return standards?.filter((x) => guideStandards?.includes(x?.id));
}

function CreateText({ standards }: { standards: any[] | null | undefined }) {
  if (!standards || standards.length > 0) return null;

  return (
    <Typography variant="caption">
      Finner du ikke standarden i listen? Da er den mest sannsynlig ikke i bruk (ingen anvisninger knyttet til den). Legg til ny under gjeldende
      anvisning.
    </Typography>
  );
}

function filterStandards<
  T extends undefined | null | { name?: string | null | undefined; title?: string | null | undefined; version?: string | null | undefined },
>(standards: T[] | null | undefined, searchTerm: string): T[] | null | undefined {
  if (!searchTerm) return standards;
  if (!standards) return standards;

  var options = {
    keys: ['name', 'title', 'version'],
  };
  var fuse = new Fuse(standards, options);
  var all = fuse.search(searchTerm).map((x) => x.item);
  return all;
}
