import { FC } from 'react';
import { ReactNodeViewRenderer, NodeViewProps, Node, mergeAttributes } from '@tiptap/react';
import { setBlockType } from '@tiptap/pm/commands';
import { getNodeType } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertChartFigure: {
      insertChartFigure: (config?: string) => ReturnType;
    };
  }
}

export const createChartFigureExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;

  const chartFigure = Node.create({
    name: 'chartFigure',
    group: 'block',
    content: 'chart figcaption',
    isolating: true,
    parseHTML() {
      return [
        {
          tag: 'react-component[data-type="chartFigure"]',
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes, { 'data-type': 'chartFigure' })];
    },
    addNodeView,
    addCommands() {
      return {
        insertChartFigure:
          (config = undefined) =>
          ({ commands, state }) => {
            const type = getNodeType('chartFigure', state.schema);
            const canSetBlock = setBlockType(type)(state);
            if (!canSetBlock) {
              return false;
            }

            const chart = {
              type: 'chart',
              attrs: {
                config,
              },
            };

            const chartFigure = {
              type: 'chartFigure',
              content: [chart, { type: 'figcaption', content: [{ type: 'paragraph', content: [] }] }],
            };

            commands.insertContent(chartFigure);

            return true;
          },
      };
    },
  });
  return chartFigure;
};
