import React, { useState, useCallback, ChangeEventHandler } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'throttle-debounce';
import { Clear, Search } from '@mui/icons-material';

interface SearchProps {
  startSearch?: string;
  filter(searchTerm: string): void;
}

export function SearchField({ filter, startSearch }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState(startSearch ?? '');
  const triggerFilter = useCallback(
    debounce(300, (s) => filter(s)),
    [],
  );

  const handleFilterInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const s = event.target.value;
    setSearchTerm(s);
    triggerFilter(s);
  };

  return (
    <TextField
      variant="standard"
      margin="dense"
      name="searchTerm"
      placeholder="Søk..."
      type="text"
      onChange={handleFilterInput}
      value={searchTerm}
      fullWidth
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton size="small" onClick={() => setSearchTerm('')}>
                  <Clear fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
