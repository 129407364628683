import React from 'react';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Add } from '@mui/icons-material';
import { ALL_STANDARDS } from './standards.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddStandardDialog, StandardAdded } from './AddStandardDialog';
import { StandardDataGrid } from './StandardDataGrid';

export function StandardOverview() {
  const navigate = useNavigate();
  const { data } = useQuery(ALL_STANDARDS, { fetchPolicy: 'cache-and-network' });
  const addModal = useModal<undefined, StandardAdded>({ data: undefined });

  const addStandard = async () => {
    const result = await addModal.open();
    if (result) navigate(`${result.id}`);
  };

  const standards = data?.standards?.filter((x) => x !== null && x !== undefined) ?? [];
  return (
    <>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Standarder
          <IconButton onClick={addStandard} title="Legg til ny standard" size="large">
            <Add />
          </IconButton>
        </Typography>
        {!standards && <LoadingSpinner />}
        <StandardDataGrid standards={standards} loading={!standards} />
      </StyledPaper>
      {addModal.isOpen && <AddStandardDialog modal={addModal} />}
    </>
  );
}
