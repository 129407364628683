import React, { FC, useState } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UploadGuideFigureFiles } from './UploadGuideFigureFiles';
import useKeyDown from '../../../utils/useKeyDown';
import { GuideFigureDialogProps, GuideFigureDialogResult } from './GuideFigureDialog';

export const EditGuideFigure: FC<{
  goBackToOverview: boolean;
  editFigureId?: string;
  onBack: () => void;
  modal: Modal<GuideFigureDialogProps, GuideFigureDialogResult>;
}> = ({ editFigureId, goBackToOverview: goBackToOverview, onBack, modal }) => {
  const [figureId, setFigureId] = useState(editFigureId);

  useKeyDown('Escape', async () => {
    modal.close();
  });

  const handleOnFigureCreated = (newFigureId: string) => {
    setFigureId(newFigureId);
  };

  const handleOnClose = async () => {
    if (goBackToOverview) {
      onBack();
    } else {
      modal.close();
    }
  };

  return (
    <>
      <DialogTitle>{editFigureId ? 'Rediger figur' : 'Last opp figur'}</DialogTitle>
      <DialogContent>
        <UploadGuideFigureFiles figureId={figureId} onFigureCreated={handleOnFigureCreated} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </>
  );
};
