import React, { FC, useState } from 'react';
import { GuideCms } from './GuideCms';
import { Drawer } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { useGuideCmsContext } from './GuideCmsContext';
import { Sidebar } from './sidebar/Sidebar';
import { SidebarButtons } from './sidebar/SidebarButtons';
import useDocumentEvent from '../../utils/useDocumentEvent';

const sidebarMinWidth = 200;
const sidebarMaxWidth = 1000;
const initialSidebarWidth = 440;
const sidebarButtonsWidth = 44;

const StyledDrawer = styled(Drawer)<{ sidebarWidth: number }>`
  width: ${(props) => props.sidebarWidth}px;
  overflow-y: hidden;
  & .MuiDrawer-paper {
    width: ${(props) => props.sidebarWidth}px;
    margin-top: 64px;
    padding-bottom: 64px;
  }
`;

const StyledResizeHandle = styled.div<{ isDragging: boolean }>`
  position: fixed;
  width: 4px;
  margin-left: -2px;
  height: 100%;
  z-index: 1;
  ${({ isDragging }) => (isDragging ? `background-color: ${theme.palette.grey['300']};` : '')}
  &:hover {
    background-color: ${theme.palette.grey['300']};
  }
  cursor: ew-resize;
`;

const StyledSidebarButtonsDrawer = styled.div<{ isSidebarOpen: boolean; sidebarWidth: number; animate: boolean }>`
  position: fixed;
  display: flex;
  height: 100%;
  top: 80px;
  right: 0;
  ${({ isSidebarOpen, sidebarWidth }) => (isSidebarOpen ? `margin-right: ${sidebarWidth}px;` : ``)}
  ${({ animate }) => (animate ? `transition: margin-right ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut};` : '')}
`;

const StyledMain = styled.div<{ isSidebarOpen: boolean; sidebarWidth: number; animate: boolean }>`
  ${({ isSidebarOpen, sidebarWidth }) =>
    isSidebarOpen ? `margin-right: ${sidebarWidth + sidebarButtonsWidth}px;` : `margin-right: ${sidebarButtonsWidth}px;`}
  ${({ animate }) => (animate ? `transition: margin-right ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut};` : '')}
`;

export const Layout: FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { isSidebarOpen } = useGuideCmsContext();
  const [isDragging, setIsDragging] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(initialSidebarWidth);
  useDocumentEvent('mousemove', (e) => {
    if (isDragging) {
      e.preventDefault();
      const newWidth = window.innerWidth - e.pageX;
      const width = newWidth > sidebarMaxWidth ? sidebarMaxWidth : newWidth < sidebarMinWidth ? sidebarMinWidth : newWidth;
      setSidebarWidth(width);
    }
  });

  useDocumentEvent('mouseup', () => {
    setIsDragging(false);
    document.body.style.cursor = '';
  });
  const handleOnMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const isLeftClick = e.button === 0;
    if (isLeftClick) {
      e.preventDefault();
      document.body.style.cursor = 'ew-resize';
      setIsDragging(true);
    }
  };

  return (
    <div>
      <StyledMain isSidebarOpen={isSidebarOpen} sidebarWidth={sidebarWidth} animate={!isDragging}>
        <GuideCms isEditing={isEditing} />

        <StyledSidebarButtonsDrawer isSidebarOpen={isSidebarOpen} sidebarWidth={sidebarWidth} animate={!isDragging}>
          <SidebarButtons />
        </StyledSidebarButtonsDrawer>
      </StyledMain>
      <StyledDrawer sidebarWidth={sidebarWidth} open={isSidebarOpen} variant="persistent" anchor="right">
        {isSidebarOpen && <StyledResizeHandle isDragging={isDragging} onMouseDown={handleOnMouseDown}></StyledResizeHandle>}
        <Sidebar />
      </StyledDrawer>
    </div>
  );
};
