import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Typography } from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';

export interface VersionCommentDialogDialogResult {
  comment?: string;
}

export interface VersionCommentDialogProps {}

export const VersionCommentDialog: FC<Modal<VersionCommentDialogProps, VersionCommentDialogDialogResult>> = (modal) => {
  const [comment, setComment] = useState('');
  const { close } = modal;
  const handleOnClose = () => {
    close();
  };

  const handleOnUpdate = () => {
    close({
      comment,
    });
  };

  return (
    <Dialog open={true} onClose={handleOnClose} fullWidth={true}>
      <DialogTitle>Lagre ny versjon av innholdet</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant="body1" gutterBottom>
            Legg til en beskrivelse av endringene dine
          </Typography>
          <TextField multiline rows={4} fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button variant="contained" onClick={handleOnUpdate}>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
};
