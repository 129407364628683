import { useEffect } from 'react';

const useDocumentEvent = <K extends keyof DocumentEventMap>(eventName: K, callback: (event: DocumentEventMap[K]) => void) => {
  const handler = (event: DocumentEventMap[K]) => {
    callback(event);
  };
  useEffect(() => {
    document.addEventListener(eventName, handler);
    return () => {
      document.removeEventListener(eventName, handler);
    };
  }, [handler]);
};
export default useDocumentEvent;
