import React, { FC } from 'react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import styled from '@emotion/styled';

const StyledMathType = styled.div`
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

// Math type is no longer used, but this view is kept to ensure backward compatability with existing content
export const MathTypeView: FC<NodeViewProps> = (props) => {
  const { html } = props.node.attrs;
  return (
    <NodeViewWrapper className="react-component" style={{ display: 'inline-block' }}>
      {!props.editor.isEditable && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {props.editor.isEditable && <StyledMathType dangerouslySetInnerHTML={{ __html: html }}></StyledMathType>}
    </NodeViewWrapper>
  );
};
