import { FC } from 'react';
import { ReactNodeViewRenderer, NodeViewProps, Node, mergeAttributes } from '@tiptap/react';

export const createChartExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;

  const chart = Node.create({
    name: 'chart',
    group: 'block',
    atom: true,
    parseHTML() {
      return [
        {
          tag: 'react-component[data-type="chart"]',
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes, { 'data-type': 'chart' })];
    },
    addAttributes() {
      return {
        config: {
          default: undefined,
        },
        width: {
          default: 800,
        },
      };
    },
    addNodeView,
  });
  return chart;
};
