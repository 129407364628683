import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps, NodeViewContent } from '@tiptap/react';
import styled from '@emotion/styled';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Figure, getThumbnailUrl, useFigure } from '../../../figures/figureHelper';
import { useGuideCmsContext } from '../../../GuideCmsContext';
import { GuideFigureDialog, GuideFigureDialogProps, GuideFigureDialogResult } from '../../../figures/GuideFigureDialog';
import { useModal } from '../../../../../dialogs/useModal';
import { getHtmlId, getTypeDescription, useNumberedNode } from '../../numbering';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { useHistoricContentContext } from '../../../historicContent/HistoricContentContext';
import { BlockNode } from '../BlockNode';
import { theme } from '../../../../../theme';

const StyledToolbar = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 4px;
`;

const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
  align-items: end;
`;

const StyledPaper = styled(Paper)`
  display: inline-block;
  max-width: 800px;
  padding: ${theme.spacing(2)};
  margin: ${theme.spacing(1, 0)};
  & > img {
    margin-bottom: ${theme.spacing(2)};
    max-height: 300px;
  }
  & figcaption {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #bdbdbd;
    width: fit-content;
    min-width: 80px;

    &:before {
      font-size: 12px;
      color: #666;
      position: absolute;
      top: -10px;
      background-color: white;
      content: 'Figurtekst';
    }
  }
`;

export const ImageFigureView: FC<NodeViewProps> = (props) => {
  const { historicContent } = useHistoricContentContext();
  if (historicContent) {
    return <HistoricImageFigureView {...props} />;
  }
  return <CurrentImageFigureView {...props} />;
};

const CurrentImageFigureView: FC<NodeViewProps> = (props) => {
  const { figureId } = props.node.attrs;
  const { guideId } = useGuideCmsContext();
  const { figure, isLoading } = useFigure(guideId, figureId);
  return <ImageFigureViewInner nodeViewProps={props} figure={figure} isLoading={isLoading} />;
};

const HistoricImageFigureView: FC<NodeViewProps> = (props) => {
  const { figureId } = props.node.attrs;
  const { historicContent } = useHistoricContentContext();
  const figure = historicContent?.figures?.find((f) => f?.id === figureId) ?? undefined;
  return <ImageFigureViewInner nodeViewProps={props} figure={figure} isLoading={false} />;
};

interface ImageFigureViewProps {
  nodeViewProps: NodeViewProps;
  figure?: Figure;
  isLoading: boolean;
}

const ImageFigureViewInner: FC<ImageFigureViewProps> = ({ nodeViewProps, figure, isLoading }) => {
  const { figureId, id } = nodeViewProps.node.attrs;
  const modal = useModal<GuideFigureDialogProps, GuideFigureDialogResult>({ data: {} });
  const node = useNumberedNode(id);

  if (isLoading) {
    return <>Laster figur...</>;
  }
  if (!figure) {
    console.error(`Unknown figure ID=${figureId}`);
    return <>[Figur ID={figureId}]</>;
  }

  return (
    <>
      {modal.isOpen && <GuideFigureDialog {...modal} />}
      <NodeViewWrapper className="react-component">
        <BlockNode props={nodeViewProps}>
          <StyledPaper id={node ? getHtmlId(node) : ''} elevation={3}>
            <img src={getThumbnailUrl(figure)} />
            <div>
              <Typography variant="body2" color="text.secondary">
                {figure.files?.[0]?.fileName ?? ''}
              </Typography>
            </div>
            <StyledFooterGrid>
              <div>
                <Typography variant="body2" color="text.secondary">
                  {capitalizeFirstLetter(getTypeDescription('imageFigure'))} {node?.numbering}
                </Typography>
                <NodeViewContent className="content" as="div"></NodeViewContent>
              </div>
              <StyledToolbar>
                {nodeViewProps.editor.isEditable && (
                  <>
                    {!figure.deleted && (
                      <Tooltip title="Rediger figur">
                        <IconButton
                          tabIndex={-1}
                          onClick={() => {
                            modal.open({ editFigureId: figure.id });
                          }}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Fjern figur">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => {
                          nodeViewProps.deleteNode();
                        }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </StyledToolbar>
            </StyledFooterGrid>
          </StyledPaper>
        </BlockNode>
      </NodeViewWrapper>
    </>
  );
};
