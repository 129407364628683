import { MenuItem, type SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import React from 'react';
import { MenuButtonTooltip, MenuSelect, useRichTextEditorContext } from 'mui-tiptap';

export type OrderedListSelectOption = {
  value: string;
  label: string;
  tooltip: string;
};

const options: OrderedListSelectOption[] = [
  {
    value: '1',
    label: '1, 2, 3',
    tooltip: 'Numbers',
  },
  {
    value: 'I',
    label: 'I, II, III',
    tooltip: 'Roman numbers uppercase',
  },
  {
    value: 'a',
    label: 'a, b, c',
    tooltip: 'Letters lowercase',
  },
];

export default function MenuSelectOrderedLists() {
  const editor = useRichTextEditorContext();

  const handleSelect: (event: SelectChangeEvent<string>) => void = useCallback(
    (event) => {
      editor?.chain().focus().updateAttributes('orderedList', { type: event.target.value }).run();
    },
    [editor],
  );
  const isOrderedList = editor?.isActive('orderedList');
  const selectedValue = options.find((option) => editor?.isActive('orderedList', { type: option.value }))?.value ?? (isOrderedList ? '1' : '');

  return (
    <MenuSelect<string>
      onChange={handleSelect}
      disabled={!editor?.isEditable || !editor?.isActive('orderedList')}
      renderValue={(value) => {
        const option = options.find((o) => o.value === value);
        return <span>{option?.label}</span>;
      }}
      aria-label="Ordered list type"
      tooltipTitle="Ordered list type"
      value={selectedValue}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} disabled={!editor?.can().updateAttributes('orderedList', { type: option.value })}>
          <MenuButtonTooltip label={option.tooltip ?? ''} placement="right">
            {option.label}
          </MenuButtonTooltip>
        </MenuItem>
      ))}
    </MenuSelect>
  );
}
