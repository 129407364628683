import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { GUIDE_CONTENT } from './content.graphql';
import { Edit } from '@mui/icons-material';
import { StyledPaper } from '../../theme';
import { Guide } from '../Details';
import { formatDate } from '../../Formatters';
import { Link } from 'react-router-dom';

export const InfoBox: FC<{ guide: Guide }> = ({ guide }) => {
  const { data } = useQuery(GUIDE_CONTENT, { variables: { id: guide.id } });
  if (!data?.guide) return null;
  const content = data.guide.content;
  return (
    <StyledPaper>
      <Typography variant="h6" component="h3">
        Innhold
      </Typography>
      <Box>
        {content && (
          <p>
            Sist endret {formatDate(content?.lastChangedAt)} av {content?.lastChangedBy?.name}
          </p>
        )}
        <Button variant="contained" component={Link} to={`/guide-editor/${guide.id}/edit`} startIcon={<Edit />}>
          Endre i CMS
        </Button>
      </Box>
    </StyledPaper>
  );
};
