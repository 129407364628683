import { FC } from 'react';
import { Unpacked } from '../graphQLTypes/types';
import { GetAllStandardsQuery } from '../__generated__/graphql';
import { DataGridPro, GridRenderCellParams, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import React from 'react';
import { nbNO } from '@mui/x-data-grid-pro/locales';
import { StyledLink } from '../Components';
import { SyncDisabled } from '@mui/icons-material';
import { Box, Portal, Stack } from '@mui/material';

type Standard = NonNullable<Unpacked<GetAllStandardsQuery['standards']>>;

export const StandardDataGrid: FC<{ standards: Standard[]; loading: boolean }> = ({ standards, loading }) => {
  return (
    <Stack spacing={2}>
      <Box id="filter-panel" />
      <DataGridPro
        rows={standards}
        loading={loading}
        disableRowSelectionOnClick
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
        pagination
        slots={{
          toolbar: CustomToolbar,
        }}
        density="compact"
        columns={[
          { field: 'isValid', headerName: 'Gyldig', type: 'boolean', width: 100 },
          {
            field: 'name',
            headerName: 'Standard',
            width: 250,
            renderCell: (params: GridRenderCellParams<any, string>) => (
              <StyledLink to={`/standard/${params.row.id}`}>{params.value ?? <span style={{ fontStyle: 'italic' }}>Uten navn</span>}</StyledLink>
            ),
          },
          { field: 'title', headerName: 'Tittel', flex: 1 },
          { field: 'version', headerName: 'Utgave', width: 150 },
          { field: 'language', headerName: 'Språk' },
          { field: 'link', headerName: 'Erstattet av (EA)/ Erstatter (E)', width: 300 },
          {
            field: 'guideRevisions',
            headerName: 'Anvisninger',
            type: 'number',
            valueGetter: (value: any[]) => {
              if (!value) {
                return 0;
              }
              return value.length;
            },
          },
          {
            field: 'unableToSync',
            type: 'boolean',
            headerName: 'Kobling',
            renderCell: (params: GridRenderCellParams<any, boolean>) => {
              if (!params.row.externalId) return <SyncDisabled color="info" titleAccess="Mangler kobling til Standard Norge" />;
              return params.value ? <SyncDisabled color="error" titleAccess="Feil i kobling til Standard Norge" /> : null;
            },
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'name',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </Stack>
  );
};

function CustomToolbar(props: any) {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById('filter-panel')!}>
        <GridToolbarQuickFilter sx={{ width: '100%' }} />
      </Portal>
    </React.Fragment>
  );
}
