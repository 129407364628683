import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ExpandMore, Functions } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import katex from 'katex';

export const LatexHelp: FC = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} id="panel-header" aria-controls="panel-content">
        <span
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\LaTeX'),
          }}></span>
        -formler
      </AccordionSummary>
      <AccordionDetails>
        <p style={{ marginTop: 0 }}>
          Formler kan skrives på LaTeX-format. Se dokumentasjonen til{' '}
          <a href="https://katex.org/docs/supported" target="_blank">
            <span
              dangerouslySetInnerHTML={{
                __html: katex.renderToString('\\KaTeX'),
              }}></span>
          </a>{' '}
          for en full oversikt over hvilke funksjoner som støttes. .
        </p>
        <p>
          For å skrive formler i LaTeX-format kan man trykke på <Functions style={{ verticalAlign: 'text-bottom' }} />
          -ikonet i verktøylinjen og skrive formelen mellom $-tegnene.
        </p>
        <p>
          For hjelp til å skrive mer avanserte formler, gå til denne{' '}
          <a href="https://latexeditor.lagrida.com/" target="_blank">
            online LaTeX editoren
          </a>
          .
        </p>
        <SectionHeader>Enkel formel</SectionHeader>
        <LatexExample latex={'E = mc^2'} />
        <SectionHeader>Fonter</SectionHeader>
        <ExampleHeader>Formel i Sans Serif</ExampleHeader>
        <LatexExample latex={'\\mathsf{E = mc^2}'} />
        <ExampleHeader>Formel i kursiv Sans Serif</ExampleHeader>
        <LatexExample latex={'\\mathsfit{E} = \\mathsfit{mc}^\\mathsf{2}'} />
        <ExampleHeader>Tekst i Sans Serif</ExampleHeader>
        <LatexExample latex={'\\textsf{Dette er en tekst}'} />
        <ExampleHeader>Tekst i kursiv Sans Serif</ExampleHeader>
        <LatexExample latex={'\\textsf{\\textit{Dette er en tekst}}'} />
        <SectionHeader>Display style</SectionHeader>
        Når en formel blir høy, vil LaTeX ofte vise veldig små fonter. Dette kan man unngå ved å legge inn{' '}
        <StyledFormula>\displaystyle</StyledFormula> i formelen.
        <ExampleHeader>Eksempel 1 uten display style</ExampleHeader>
        <LatexExample latex={'\\frac{x^2}{y}'} />
        <ExampleHeader>Eksempel 2 med display style</ExampleHeader>
        <LatexExample latex={'\\displaystyle \\frac{x^2}{y}'} />
        <ExampleHeader>Eksempel 3 uten display style</ExampleHeader>
        <LatexExample latex={'\\frac{\\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}'} />
        <ExampleHeader>Eksempel 4 med display style på formelen og på summen</ExampleHeader>I mer kompliserte formler må man legge på{' '}
        <StyledFormula>\displaystyle</StyledFormula> flere steder for å oppnå ønsket uttrykk. Da må man også legge på{' '}
        <StyledFormula>\mathsfit</StyledFormula> på nytt for å få riktig stilsetting:
        <br />
        <LatexExample latex={'\\displaystyle \\frac{\\displaystyle \\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}'} />
        <SectionHeader>Enheter</SectionHeader>
        For å skrive enheter, f.eks. (m/s) etter en formel, kan man bruke <StyledFormula>\text</StyledFormula> eller{' '}
        <StyledFormula>\textsf</StyledFormula> for å skrive tekst og <StyledFormula>\quad</StyledFormula> eller <StyledFormula>\qquad</StyledFormula>{' '}
        for å lage et mellomrom.
        <ExampleHeader>Eksempel 1</ExampleHeader>
        <LatexExample latex={'\\displaystyle C_L = \\sqrt{\\frac{E}{\\rho}} \\qquad \\text{(m/s)}'} />
        <ExampleHeader>Eksempel 2</ExampleHeader>
        <LatexExample latex={'\\displaystyle C_L = \\sqrt{\\frac{E}{\\rho}} \\quad \\text{(m/s)}'} />
      </AccordionDetails>
    </Accordion>
  );
};

const BlockKatex = styled.div`
  margin-top: 10px;
`;

const LatexExample: FC<{ latex: string }> = ({ latex }) => {
  return (
    <>
      <StyledFormula>{`$${latex}$`}</StyledFormula>
      <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString(latex) }}></BlockKatex>
    </>
  );
};

const SectionHeader: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h6" component="h3" marginTop={2}>
      {children}
    </Typography>
  );
};

const ExampleHeader = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 6px;
`;

const StyledFormula = styled.span<{ marginRight?: boolean }>`
  background: #e6edf0;
  color: #000;
  font-family: monospace;
  font-size: 0.8rem;
  padding: 0.2rem;
  ${({ marginRight }) => marginRight && 'margin-right: 10px;'}
`;
