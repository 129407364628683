import { gql } from '../../__generated__';

gql(/* GraphQL */ `
  fragment GuideContentItem on GuideContent {
    id
    version
    content
    schemaVersion
    lastChangedAt
    lastChangedBy {
      name
    }
    figures {
      ...GuideFigureItem
    }
    comment
  }
`);

gql(/* GraphQL */ `
  fragment GuideFigureItem on GuideFigureModel {
    id
    deleted
    files {
      id
      fileName
      mimeType
      type
      url
      metadata {
        name
        value
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment GuideModelWithCurrentFiguresItem on GuideModel {
    id
    figures(where: { deleted: { eq: false } }) {
      ...GuideFigureItem
    }
  }
`);

export const GUIDE_CONTENT = gql(/* GraphQL */ `
  query GetGuideContent($id: UUID!) {
    guide(id: $id) {
      id
      docId
      docName
      docTitle
      content {
        ...GuideContentItem
      }
    }
  }
`);

export const GUIDE_CONTENT_VERSION = gql(/* GraphQL */ `
  query GetGuideContentVersion($guideId: UUID!, $version: Int!) {
    guideContentVersion(guideId: $guideId, version: $version) {
      ...GuideContentItem
    }
  }
`);

export const GUIDE_CONTENT_VERSIONS = gql(/* GraphQL */ `
  query GetGuideContentVersions($id: UUID!) {
    guide(id: $id) {
      id
      docName
      docTitle
      content {
        id
        version
      }
      contents(order: { version: DESC }) {
        id
        version
        createdAt
        createdBy
        lastChangedAt
        lastChangedBy {
          name
        }
        comment
      }
    }
  }
`);

export const CREATE_NEW_GUIDE_CONTENT_VERSION = gql(/* GraphQL */ `
  mutation GuideCreateNewContentVersion($input: GuideCreateNewContentVersionInput!) {
    guideCreateNewContentVersion(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        contentId
        content {
          version
          content
        }
        guideLinks {
          id
        }
        standards {
          id
        }
      }
    }
  }
`);

export const RESTORE_GUIDE_CONTENT_VERSION = gql(/* GraphQL */ `
  mutation GuideRestoreContentVersion($input: GuideRestoreContentVersionInput!) {
    guideRestoreContentVersion(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        contentId
        content {
          version
          content
        }
        guideLinks {
          id
        }
        standards {
          id
        }
      }
    }
  }
`);

export const IMPORT_LAST_PUBLISHED_VERSION = gql(/* GraphQL */ `
  mutation GuideImportLastPublishedVersion($input: GuideImportLastPublishedVersionInput!) {
    guideImportLastPublishedVersion(input: $input) {
      importLastPublishedVersionPayload {
        cmsContent
        guideModel {
          ...GuideModelWithCurrentFiguresItem
        }
      }
    }
  }
`);

export const GET_GUIDE_FIGURES = gql(/* GraphQL */ `
  query GetGuideFigures($guideId: UUID!) {
    guide(id: $guideId) {
      ...GuideModelWithCurrentFiguresItem
    }
  }
`);

export const UPLOAD_FIGURE = gql(/* GraphQL */ `
  mutation UploadGuideFigureFile($input: GuideUploadFigureFileInput!) {
    guideUploadFigureFile(input: $input) {
      figure {
        id
      }
      guide {
        ...GuideModelWithCurrentFiguresItem
      }
    }
  }
`);

export const DELETE_FIGURE = gql(/* GraphQL */ `
  mutation DeleteGuideFigure($input: GuideDeleteFigureInput!) {
    guideDeleteFigure(input: $input) {
      guideModel {
        ...GuideModelWithCurrentFiguresItem
      }
    }
  }
`);

export const DELETE_FIGURE_FILE = gql(/* GraphQL */ `
  mutation DeleteGuideFigureFile($input: GuideDeleteFigureFileInput!) {
    guideDeleteFigureFile(input: $input) {
      guideFigureModel {
        ...GuideFigureItem
      }
    }
  }
`);

export const SUBSCRIBE_FIGURES_UPDATED = gql(/* GraphQL */ `
  subscription FiguresUpdated($guideId: UUID!) {
    figuresUpdated(guideId: $guideId) {
      id
      figures(where: { deleted: { eq: false } }) {
        ...GuideFigureItem
      }
    }
  }
`);
