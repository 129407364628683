import OrderedList from '@tiptap/extension-ordered-list';

export const CustomOrderedList = OrderedList.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      type: {
        default: '1',
        parseHTML: (element: any) => element.getAttribute('type') || '1',
        renderHTML: (attributes) => {
          return {
            type: attributes.type, // Adds the type attribute to <ol>.
          };
        },
      },
    };
  },
});
