import { FC } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../theme';
import katex from 'katex';

export interface SymbolsDialogResult {
  selectedCharacter?: string;
  selectedLatexCode?: string;
}

export interface SymbolsDialogProps {}

interface Symbol {
  character: string;
  description?: string;
  unicode: string;
  latexCode?: string;
}

interface Symbols {
  greekSmall: Symbol[];
  greekCapital: Symbol[];
  other: Symbol[];
}

const symbols: Symbols = {
  greekSmall: [
    { character: 'α', description: 'Liten alfa', unicode: 'U+03B1', latexCode: '\\alpha' },
    { character: 'β', description: 'Liten beta', unicode: 'U+03B2', latexCode: '\\beta' },
    { character: 'γ', description: 'Liten gamma', unicode: 'U+03B3', latexCode: '\\gamma' },
    { character: 'δ', description: 'Liten delta', unicode: 'U+03B4', latexCode: '\\delta' },
    { character: 'ε', description: 'Liten epsilon', unicode: 'U+03B5', latexCode: '\\epsilon' },
    { character: 'ζ', description: 'Liten zeta', unicode: 'U+03B6', latexCode: '\\zeta' },
    { character: 'η', description: 'Liten eta', unicode: 'U+03B7', latexCode: '\\eta' },
    { character: 'θ', description: 'Liten theta', unicode: 'U+03B8', latexCode: '\\theta' },
    { character: 'ι', description: 'Liten iota', unicode: 'U+03B9', latexCode: '\\iota' },
    { character: 'κ', description: 'Liten kappa', unicode: 'U+03BA', latexCode: '\\kappa' },
    { character: 'λ', description: 'Liten lambda', unicode: 'U+03BB', latexCode: '\\lambda' },
    { character: 'μ', description: 'Liten my', unicode: 'U+03BC', latexCode: '\\mu' },
    { character: 'ν', description: 'Liten ny', unicode: 'U+03BD', latexCode: '\\nu' },
    { character: 'ξ', description: 'Liten xi', unicode: 'U+03BE', latexCode: '\\xi' },
    { character: 'ο', description: 'Liten omikron', unicode: 'U+03BF', latexCode: '\\omicron' },
    { character: 'π', description: 'Liten pi', unicode: 'U+03C0', latexCode: '\\pi' },
    { character: 'ρ', description: 'Liten rho', unicode: 'U+03C1', latexCode: '\\rho' },
    { character: 'σ', description: 'Liten sigma', unicode: 'U+03C3', latexCode: '\\sigma' },
    { character: 'τ', description: 'Liten tau', unicode: 'U+03C4', latexCode: '\\tau' },
    { character: 'υ', description: 'Liten ypsilon', unicode: 'U+03C5', latexCode: '\\upsilon' },
    { character: 'φ', description: 'Liten phi', unicode: 'U+03C6', latexCode: '\\phi' },
    { character: 'χ', description: 'Liten chi', unicode: 'U+03C7', latexCode: '\\chi' },
    { character: 'ψ', description: 'Liten psi', unicode: 'U+03C8', latexCode: '\\psi' },
    { character: 'ω', description: 'Liten omega', unicode: 'U+03C9', latexCode: '\\omega' },
  ],
  greekCapital: [
    { character: 'Α', description: 'Stor alfa', unicode: 'U+0391', latexCode: '\\Alpha' },
    { character: 'Β', description: 'Stor beta', unicode: 'U+0392', latexCode: '\\Beta' },
    { character: 'Γ', description: 'Stor gamma', unicode: 'U+0393', latexCode: '\\Gamma' },
    { character: 'Δ', description: 'Stor delta', unicode: 'U+0394', latexCode: '\\Delta' },
    { character: 'Ε', description: 'Stor epsilon', unicode: 'U+0395', latexCode: '\\Epsilon' },
    { character: 'Ζ', description: 'Stor zeta', unicode: 'U+0396', latexCode: '\\Zeta' },
    { character: 'Η', description: 'Stor eta', unicode: 'U+0397', latexCode: '\\Eta' },
    { character: 'Θ', description: 'Stor theta', unicode: 'U+0398', latexCode: '\\Theta' },
    { character: 'Ι', description: 'Stor iota', unicode: 'U+0399', latexCode: '\\Iota' },
    { character: 'Κ', description: 'Stor kappa', unicode: 'U+039A', latexCode: '\\Kappa' },
    { character: 'Λ', description: 'Stor lambda', unicode: 'U+039B', latexCode: '\\Lambda' },
    { character: 'Μ', description: 'Stor my', unicode: 'U+039C', latexCode: '\\Mu' },
    { character: 'Ν', description: 'Stor ny', unicode: 'U+039D', latexCode: '\\Nu' },
    { character: 'Ξ', description: 'Stor xi', unicode: 'U+039E', latexCode: '\\Xi' },
    { character: 'Ο', description: 'Stor omikron', unicode: 'U+039F', latexCode: '\\Omicron' },
    { character: 'Π', description: 'Stor pi', unicode: 'U+03A0', latexCode: '\\Pi' },
    { character: 'Ρ', description: 'Stor rho', unicode: 'U+03A1', latexCode: '\\Rho' },
    { character: 'Σ', description: 'Stor sigma', unicode: 'U+03A3', latexCode: '\\Sigma' },
    { character: 'Τ', description: 'Stor tau', unicode: 'U+03A4', latexCode: '\\Tau' },
    { character: 'Υ', description: 'Stor ypsilon', unicode: 'U+03A5', latexCode: '\\Upsilon' },
    { character: 'Φ', description: 'Stor phi', unicode: 'U+03A6', latexCode: '\\Phi' },
    { character: 'Χ', description: 'Stor chi', unicode: 'U+03A7', latexCode: '\\Chi' },
    { character: 'Ψ', description: 'Stor psi', unicode: 'U+03A8', latexCode: '\\Psi' },
    { character: 'Ω', description: 'Stor omega', unicode: 'U+03A9', latexCode: '\\Omega' },
  ],
  other: [
    { character: '×', description: 'Ganger', unicode: 'U+00D7' },
    { character: '·', description: 'Multipliser', unicode: 'U+00B7' },
    { character: '−', description: 'Minus', unicode: 'U+2212' },
    { character: '±', description: 'Pluss minus', unicode: 'U+00B1' },
    { character: '~', description: 'Tilde', unicode: 'U+007E' },
    { character: '≤', description: 'Mindre eller lik', unicode: 'U+2264' },
    { character: '≥', description: 'Større eller lik', unicode: 'U+2265' },
    { character: '°', description: 'Grader', unicode: 'U+00B0' },
    { character: '‰', description: 'Promille', unicode: 'U+2030' },
    { character: '≈', description: 'Tilnærmet lik', unicode: 'U+2248' },
    { character: '«', description: 'Anførselstegn venstre', unicode: 'U+00AB' },
    { character: '»', description: 'Anførselstegn høyre', unicode: 'U+00BB' },
    { character: '–', description: 'Tankestrek', unicode: 'U+2013' },
    { character: "'", description: 'Merket', unicode: 'U+0027' },
    { character: '″', description: 'Tommer', unicode: 'U+2033' },
  ],
};

export const SymbolsDialog: FC<Modal<SymbolsDialogProps, SymbolsDialogResult>> = (modal) => {
  const { close } = modal;
  const handleOnClose = () => {
    close();
  };

  const handleOnSymbolClick = (symbol: Symbol) => {
    if (symbol.latexCode) {
      close({ selectedLatexCode: symbol.latexCode });
    } else {
      close({ selectedCharacter: symbol.character });
    }
  };

  return (
    <Dialog
      disableEnforceFocus // Must be set in order for the MathType editor to work
      open={true}
      onClose={handleOnClose}
      maxWidth="md"
      fullWidth={true}>
      <DialogTitle>Symboler</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid size={{ xs: 6 }}>
            <Typography variant="subtitle2" component="h3">
              Greske symboler (<RenderedLaTeX latex={`\\LaTeX`} />)
            </Typography>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Typography variant="subtitle2" component="h3">
              Andre symboler (Unicode)
            </Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            {symbols.greekCapital.map((symbol) => (
              <SymbolComponent key={symbol.unicode} symbol={symbol} onClick={handleOnSymbolClick} />
            ))}
          </Grid>
          <Grid size={{ xs: 3 }}>
            {symbols.greekSmall.map((symbol) => (
              <SymbolComponent key={symbol.unicode} symbol={symbol} onClick={handleOnSymbolClick} />
            ))}
          </Grid>
          <Grid size={{ xs: 6 }}>
            {symbols.other.map((symbol) => (
              <SymbolComponent key={symbol.unicode} symbol={symbol} onClick={handleOnSymbolClick} />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
};

const SymbolComponent: FC<{ symbol: Symbol; onClick: (symbol: Symbol) => void }> = ({ symbol, onClick }) => {
  return (
    <Link
      component="button"
      key={symbol.unicode}
      style={{ textDecoration: 'none', display: 'block' }}
      onClick={() => onClick(symbol)}
      title={`${symbol.description} (${symbol.latexCode ? symbol.latexCode : symbol.unicode})`}>
      {symbol.latexCode ? (
        <RenderedLaTeX latex={symbol.latexCode} />
      ) : (
        <div style={{ display: 'inline-block', fontSize: '1.3em', width: '1em' }}>{symbol.character}</div>
      )}
      <div style={{ display: 'inline-block', marginLeft: '10px', color: theme.palette.text.primary }}>{symbol.description}</div>
    </Link>
  );
};

const RenderedLaTeX: FC<{ latex: string }> = ({ latex }) => {
  return <span dangerouslySetInnerHTML={{ __html: katex.renderToString(`\\mathsfit{${latex}}`) }}></span>;
};
