import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { EmojiSymbols } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { SymbolsDialog, SymbolsDialogProps, SymbolsDialogResult } from '../../../dialogs/SymbolsDialog';

export const SymbolsToolbarButton: FC = () => {
  const modal = useModal<SymbolsDialogProps, SymbolsDialogResult>({ data: { html: '' } });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    if (!editor) return;

    const payload = await modal.open();
    if (payload) {
      insertCharacter(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <SymbolsDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={EmojiSymbols} tooltipLabel="Symboler" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const insertCharacter = (editor: Editor, payload: SymbolsDialogResult) => {
  if (payload.selectedLatexCode) {
    const isEditingMath = isCursorInsideMathEditor(editor);
    if (isEditingMath) {
      editor?.chain().focus().insertContent(payload.selectedLatexCode).run();
    } else {
      editor?.chain().focus().insertContent(`$\\mathsfit{${payload.selectedLatexCode}}$ `).run();
    }
  } else if (payload.selectedCharacter) {
    editor?.chain().focus().insertContent(payload.selectedCharacter).run();
  }
};

/**
 * Determine if the current cursor position is inside an inline math environment defined by `$...$`.
 *
 * In Tiptap, inline nodes (like `internalLink`) do not appear as text in `textContent`.
 * We rebuild a "virtual line" that includes text nodes and represents inline nodes as placeholders.
 * This ensures the cursor offset aligns correctly with a string we can search for `$` characters.
 *
 * If the number of `$` before the cursor is odd, the cursor is inside math mode.
 */
const isCursorInsideMathEditor = (editor: Editor) => {
  const { state } = editor.view;
  const { $from } = state.selection;
  const parent = $from.parent;
  const offset = $from.parentOffset;

  // Construct a string representation of the current line of text,
  // including placeholders for inline nodes that are not text.
  let virtualLine = '';
  parent.content.forEach((child) => {
    virtualLine += child.isText ? (child.text ?? '') : '�'; // Use a placeholder for non-text inline nodes
  });

  // The offset now corresponds directly to a position in virtualLine.
  // Extract the substring before the cursor position.
  const substring = virtualLine.slice(0, offset);

  // Count how many `$` have appeared so far.
  const countDollarSigns = (substring.match(/\$/g) || []).length;

  // If we've encountered an odd number of `$`, we're currently inside math.
  return countDollarSigns % 2 === 1;
};
