import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack } from '@mui/material';

import { ADD, FETCH_STANDARD } from './standards.graphql';
import { StandardAddMutation } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';
import { GetApp } from '@mui/icons-material';

export type StandardAdded = NonNullable<StandardAddMutation['standardAdd']>['standardModel'];

export function AddStandardDialog({ modal }: { modal: Modal<undefined, StandardAdded> }) {
  const [name, setName] = useState<string>();
  const [externalId, setExternalId] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [version, setVersion] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [parent, setParent] = useState<string>();
  const [child, setChild] = useState<string>();
  const [isPublished, setIsPublished] = useState<boolean>();

  const [productIdError, setProductIdError] = useState<string | undefined>(undefined);
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);

  const resetState = () => {
    setTitle(undefined);
    setExternalId(undefined);
    setVersion(undefined);
    setLanguage(undefined);
    setParent(undefined);
    setChild(undefined);
    setIsPublished(undefined);
    setAlreadyExists(false);
  };

  const [fetchStandard, { loading }] = useLazyQuery(FETCH_STANDARD, {
    onCompleted: (d) => {
      if (d.standardResponse?.error) {
        if (d.standardResponse?.error?.code === 'NOT_FOUND') setProductIdError('Fant ikke standarden på standard.no.');
        else if (d.standardResponse?.error?.code === 'ARGUMENT_ERROR') setProductIdError('Klarte ikke lese inn standarden fra standard.no.');
        else setProductIdError(d.standardResponse?.error?.message ?? 'Det skjedde en feil ved henting av standarden');
        resetState();
      } else if (d.standardResponse?.standard) {
        const standard = d.standardResponse.standard;
        setProductIdError(undefined);
        setName(standard.name !== null ? standard.name : undefined);
        setExternalId(standard.externalId !== null ? standard.externalId : undefined);
        setTitle(standard.title !== null ? standard.title : undefined);
        setVersion(standard.version !== null ? standard.version : undefined);
        setLanguage(standard.language !== null ? standard.language : undefined);
        setParent(standard.parent !== null ? standard.parent : undefined);
        setChild(standard.child !== null ? standard.child : undefined);
        setIsPublished(standard.isValid !== null ? standard.isValid : undefined);
        setAlreadyExists(d.standardResponse.isDuplicate);
      }
    },
    onError: () => {
      setProductIdError('Det skjedde en feil ved henting av standarden');
      resetState();
    },
    fetchPolicy: 'network-only',
  });

  const [addStandard] = useMutation(ADD, {
    variables: {
      input: {
        isValid: isPublished ?? true,
        name: name,
        externalId: externalId,
        title: title,
        version: version,
        language: language,
        parent: parent,
        child: child,
      },
    },
  });

  const handleClose = async () => {
    modal.close();
  };

  const handleOk = async () => {
    const result = await addStandard();
    const newStandard = result.data?.standardAdd.standardModel;
    if (newStandard) modal.close(result.data?.standardAdd.standardModel);
    else {
      if (result.data?.standardAdd.errors?.some((e) => e.code === 'StandardAlreadyExistsError')) setAlreadyExists(true);
    }
  };

  const doFetchStandard = () => {
    setProductIdError(undefined);
    fetchStandard({ variables: { name: name! } });
  };

  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value ?? undefined);
    setExternalId(undefined);
    setProductIdError(undefined);
  };

  const isValidStandard = name && version && title;
  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Legg til ny standard</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2} width={500}>
            <TextField
              label="Standard"
              type="text"
              required
              error={!!productIdError}
              helperText={productIdError ?? 'Hele navnet til standarden som vist på standard.no f.eks: NS-EN 13200-4:2023'}
              value={name ?? ''}
              onChange={updateName}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton title="Fyll ut resten fra standard.no" disabled={!name && !loading} onClick={doFetchStandard}>
                        {!loading && <GetApp />}
                        {loading && <CircularProgress size={25} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <TextField
              label="Tittel"
              type="text"
              multiline
              slotProps={title ? { inputLabel: { shrink: true } } : undefined}
              rows={2}
              required
              value={title ?? ''}
              onChange={(e) => setTitle(e.target.value ?? undefined)}
            />
            <TextField label="Utgave" type="text" required value={version ?? ''} onChange={(e) => setVersion(e.target.value ?? undefined)} />
            <TextField name="language" label="Språk" type="text" value={language ?? ''} onChange={(e) => setLanguage(e.target.value ?? undefined)} />
            <TextField name="parent" label="Erstatter" type="text" value={parent ?? ''} onChange={(e) => setParent(e.target.value ?? undefined)} />
            {alreadyExists && <Alert severity="error">Denne standarden er allerede lagt inn</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} variant="contained" color="primary" disabled={!isValidStandard || alreadyExists}>
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
