import 'katex/dist/katex.min.css';
import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { AddGuideLinkToolbarButton } from './extensions/toolbar/AddGuideLinkToolbarButton';
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonUnderline,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
  LinkBubbleMenu,
  MenuButtonEditLink,
  MenuButtonSuperscript,
  MenuButtonSubscript,
  RichTextField,
  RichTextEditorProvider,
  MenuButtonUndo,
  MenuButtonRedo,
  MenuButtonHighlightToggle,
} from 'mui-tiptap';
import styled from '@emotion/styled';
import { MathematicsStyles } from './extensions/Mathematics';
import { AiToolbarButton } from './extensions/toolbar/AiToolbarButton';
import { FigureToolbarButton } from './extensions/toolbar/FigureToolbarButton';
import { Public } from '@mui/icons-material';
import { AddInternalLinkToolbarButton } from './extensions/toolbar/AddInternalLinkToolbarButton';
import { useSubscription } from '@apollo/client';
import { SUBSCRIBE_FIGURES_UPDATED } from '../content.graphql';
import AddTableToolbarButton from './extensions/toolbar/AddTableToolbarButton';
import TableBubbleMenu from './extensions/TableBubbleMenu';
import { AddAppToolbarButton } from './extensions/toolbar/AddAppToolbarButton';
import { AddChartToolbarButton } from './extensions/toolbar/AddChartToolbarButton';
import { FormulaToolbarButton } from './extensions/toolbar/FormulaToolbarButton';
import { useCommentsContext } from '../sidebar/comments/CommentsContext';
import { SymbolsToolbarButton } from './extensions/toolbar/SymbolsToolbarButton';
import { AddStandardLinkToolbarButton } from './extensions/toolbar/AddStandardLinkToolbarButton';
import { TextBubbleMenu } from './extensions/TextBubbleMenu';
import MenuSelectOrderedLists from './extensions/toolbar/MenuSelectOrderedLists';

const StyledEditor = styled.div<{ numberOfColumns: number }>`
  .tiptap.ProseMirror {
    padding-left: ${(props) => props.numberOfColumns * 35 + 8}px;
    padding-right: 8px;
    font-size: 16px;
    ${MathematicsStyles}
    figure {
      margin: 16px 0;
    }
    h1,
    h2,
    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      margin-bottom: 7px;
      line-height: 1.3;
    }
    ul,
    ol {
      padding-left: 18px;
    }
    ol[type='I'] {
      list-style-type: upper-roman;
    }
    ol[type='1'] {
      list-style-type: decimal;
    }
    ol[type='a'] {
      list-style-type: lower-alpha;
    }

    // Hide block node actions on nested nodes
    *[class*='node-'] {
      *[class*='node-'] {
        .block-node-actions {
          display: none;
        }
        .block-node-content {
          margin-left: 0;
        }
      }
    }
  }
`;

export const GuideEditor: FC<{ guideId: string; editor: Editor; menuComponent?: React.ReactNode }> = ({ guideId, editor, menuComponent }) => {
  const { isCommentsEnabled } = useCommentsContext();
  let numberOfColumns = 0;
  if (isCommentsEnabled) {
    numberOfColumns = 1;
  }

  return (
    <StyledEditor numberOfColumns={numberOfColumns}>
      <RichTextEditorProvider editor={editor}>
        {editor?.isEditable && <SubscribeToFigureUpdates guideId={guideId} />}
        <RichTextField
          variant={editor?.isEditable ? 'outlined' : 'standard'}
          MenuBarProps={{ stickyOffset: 64 }}
          controls={
            editor?.isEditable && (
              <MenuControlsContainer>
                {menuComponent && <div style={{ width: '100%' }}>{menuComponent}</div>}
                <MenuButtonUndo />
                <MenuButtonRedo />
                <MenuDivider />
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonUnderline />
                <MenuButtonHighlightToggle />
                <MenuDivider />
                <MenuButtonSuperscript />
                <MenuButtonSubscript />
                <FormulaToolbarButton />
                <SymbolsToolbarButton />
                <MenuDivider />
                <MenuSelectTextAlign />
                <MenuButtonBulletedList />
                <MenuButtonOrderedList />
                <MenuSelectOrderedLists />
                <MenuDivider />
                <MenuButtonEditLink IconComponent={Public} tooltipLabel="Ekstern lenke" />
                <AddStandardLinkToolbarButton />
                <AddGuideLinkToolbarButton guideId={guideId} />
                <AddInternalLinkToolbarButton />
                <MenuDivider />
                <FigureToolbarButton />
                <AddAppToolbarButton />
                <AddChartToolbarButton />
                <AddTableToolbarButton />
                <MenuDivider />
                <AiToolbarButton />
              </MenuControlsContainer>
            )
          }></RichTextField>

        <TableBubbleMenu placement="top-start" fallbackPlacements={['bottom-start']} />
        <LinkBubbleMenu />
        <TextBubbleMenu guideId={guideId} />
      </RichTextEditorProvider>
    </StyledEditor>
  );
};

const SubscribeToFigureUpdates: FC<{ guideId: string }> = ({ guideId }) => {
  useSubscription(SUBSCRIBE_FIGURES_UPDATED, { variables: { guideId } });
  return null;
};
